.checkout-section-1 {
    margin-top: 100px;
    padding-left: 8vw;
    padding-right: 8vw;
}
.checkout-p {
    padding: 0;
    margin: 0;
    margin-left: 10px;
}
.checkout-p:nth-child(2) {
    color: rgba(225, 225, 225, .4);
    font-size: small;
}
.aside-1 {
    color: white;
    width: fit-content;
}
.checkout-order-info {
    border-bottom: 1px solid grey;
}
.aside-1 h3 {
    font-weight: 400;
}
.cyberstickbent {
    width: 100px;
    margin-left: -20px;
}
.amount-controls {
    font-size: 1rem;
}
.increase {
    margin-bottom: 5px;
}
.amount-wrapper {
    margin-top: -12px;
    position: absolute;
    right: 23%;
}

.aside-1 i {
    font-size: 1em;
    cursor: pointer;
    color: rgba(225, 225, 225, .4);
    transition: all .3s;
}
.aside-1 i:hover {
    color: rgba(225, 225, 225, .8);
}
.price-wrapper {
    position: absolute;    
    right: 5px;
    line-height: 1.5em;    
    margin-left: 30px;
}
.price,
.amount {
    font-size: 1.1rem;
}
.remove {
    background: linear-gradient(to right, #EC1EB1, #EC1EB1, #5E01BA, #5E01BA);    
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-size: .8em;
    box-sizing: border-box;
    cursor: pointer;
    transition: all .3s;
}
.remove:hover {
    /*-webkit-transform: scale(1.1);    */
    font-size: .9em;
}    

@media screen and (max-width: 1000px){
    .checkout-section-1 {
        padding-right: 0;
        padding-left: 2vw;
    }
}

// bootstrap small screen breakpoint {
@media screen and (max-width: 575px){
    .cyberstickbent {
        margin-top: -50px;
        width: 200px;
    }
    .checkout-p-wrapper {
        margin-top: -30px;
    }    
    .checkout-p {
        margin-left: 0px;
    }
    .amount-wrapper {
        margin-top: 10px;
        position: unset;
    }
    .amount {
        padding-left: 0 !important;
    }
    .price-wrapper {
        margin-top: 10px;    
        margin-left: 0px;
        position: unset;
    }
    .checkout-section-1 {
        padding-left: 3vw;
    }
    .price {
        background-color: rgb(30, 30, 30);
        padding: 3px 20px 3px 20px;
    }
    .remove {
        margin-left: 35px;
    }
}

@media screen and (max-width: 400px){
    .checkout-p:nth-child(2) {
        font-size: x-small;
    }
}
